<template>
  <div class="page" v-if="detailInfo">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <span>{{ `${bizNo}-${detailInfo.homeBasicDetailDTO.customerName}` }}</span>
      </template>
    </van-nav-bar>
    <van-tabs class="tabs" v-model="currentTab" line-height="1px" line-width="4em" title-inactive-color="#41444E"
      title-active-color="#3C86FF">
      <van-tab title="客户信息" name="basic">
        <CardBasic :detailInfo="detailInfo" :bizNo="bizNo" :readonly="!!readonly" :addrData="addrData"></CardBasic>
      </van-tab>
      <van-tab title="家访信息" name="visit">
        <CardVisit :detailInfo="detailInfo" :homeNo="homeNo" :readonly="!!readonly" :addrData="addrData"></CardVisit>
      </van-tab>
      <van-tab title="影像资料" name="attach">
        <CardAttach :detailInfo="detailInfo" :homeNo="homeNo" :readonly="!!readonly"></CardAttach>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import Api from "@/api/caseHandle";
import ApiDetail from "@/api/caseDetail";
import { Toast } from "vant";

export default {
  components: {
    CardBasic: () => import("./components/CardBasic"),
    CardVisit: () => import("./components/CardVisit"),
    CardAttach: () => import("./components/CardAttach"),
  },
  data() {
    return {
      homeNo: this.$route.params.homeNo,
      bizNo: this.$route.query.bizNo || "",
      readonly: this.$route.query.readonly || "",
      currentTab: this.$route.query.currentTab || "basic",
      addrData: {},
      detailInfo: null,
    };
  },
  watch: {
    currentTab: {
      immediate: true,
      handler(val) {
        const targetUrl = this.$router.resolve({
          name: this.$route.name,
          params: this.$route.param,
          query: {
            ...this.$route.query,
            currentTab: val,
          },
        }).href;
        history.replaceState(null, "", targetUrl);
      },
    },
  },
  methods: {
    // 加载详情
    async loadDetail() {
      const loading = Toast.loading();
      try {
        const res = await Api.findDetailByHomeNo(this.homeNo);
        this.detailInfo = res.data;
        loading.close();
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 客户居住地相关信息
    async addrInfo() {
      try {
        const res = await ApiDetail.addrInfo({ bizNo: this.bizNo });
        this.addrData = res.data || {};
      } catch (error) {
        Toast.fail(error);
      }
    },

    initData() {
      this.loadDetail();
      this.addrInfo();
    }
  },
  mounted() {
    this.initData();
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/variables.less";

.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  /deep/ .van-nav-bar {
    .van-nav-bar__title {
      max-width: 80%;
    }
  }
}

.tabs {
  flex: 1;
  overflow-y: auto;

  /deep/ .van-tabs__content {
    flex: 1;

    .van-tab__pane {
      background: @pageBg;
    }
  }

  /deep/ .van-tabs__nav--line {
    justify-content: space-between;
    padding-bottom: 10px;
  }

  /deep/ .van-tabs__line {
    background: #3c86ff;
  }
}
</style>
