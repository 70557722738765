import axios from '@/libs/api.request';

const Api = {};

/**
 * @description 客户基本信息
 */
Api.customerInfo = opts => axios.request({
    url: `/api/app/home/caseHandle/case/customer/info/${opts.bizNo}`,
    data: opts,
});

/**
 * @description 客户居住地相关信息
 */
Api.addrInfo = opts => axios.request({
    url: `/api/app/home/caseHandle/case/customer/addr/info/${opts.bizNo}`,
    data: opts,
});

/**
 * @description 客户GPS定位相关信息
 */
Api.gpsInfo = opts => axios.request({
    url: `/api/app/home/caseHandle/case/customer/gps/info/${opts.bizNo}`,
    data: opts,
});

/**
 * @description 客户GPS状态相关信息-获取聚合gps状态
 */
Api.gpsStatus = opts => axios.request({
    url: `/api/app/home/caseHandle/case/customer/gps/status/${opts.bizNo}`,
    data: opts,
});

/**
 * @description 获取gps厂商地图查看地址，type 1定位，2跟踪，3轨迹
 */
Api.gpsWebMap = opts => axios.request({
    url: `/api/app/home/caseHandle/webMap/${opts.vendor}/${opts.imei}/${opts.type}/${opts.bizNo}`,
    method: 'get'
});

/**
 * @description 查询客户GPS设备经纬度
 */
Api.gpsLocation = opts => axios.request({
    url: `/api/app/home/caseHandle/case/customer/ime/gps/location/${opts.bizNo}`,
    method: 'post'
});

/**
 * @description 客户还款信息
 */
Api.customerLoanInfo = opts => axios.request({
    url: `/api/app/home/caseHandle/case/customer/loan/${opts.bizNo}`,
    data: opts,
});

/**
 * @description 客户账单逾期信息
 */
Api.loanOverdueInfo = opts => axios.request({
    url: `/api/app/home/caseHandle/case/customer/loan/overdue/info/${opts.bizNo}`,
    data: opts,
});

/**
 * @description 案件回款账户信息
 */
Api.bankInfo = opts => axios.request({
    url: `/api/app/home/caseHandle/case/bank/info/${opts.bizNo}`,
    data: opts,
});

/**
 * @description 案件用户联系人信息
 */
Api.relatePersonInfo = opts => axios.request({
    url: `/api/app/home/caseHandle/case/relate/person/${opts.bizNo}`,
    data: opts,
});

/**
 * @description 敏感信息查询日志记录
 */
Api.sensitiveInfo = opts => axios.request({
    url: `/api/app/home/caseHandle/case/sensitive/info/${opts.bizNo}/${opts.source}/${opts.ext}`,
    data: opts,
});

/**
 * @description 家访客户情况登记新增
 */
Api.familyCreate = opts => axios.request({
    url: `/api/app/home/caseHandle/case/family/create`,
    data: opts,
});

/**
 * @description 家访客户情况登记分页查询
 */
Api.familyPage = opts => axios.request({
    url: `/api/app/home/caseHandle/case/family/page`,
    data: opts,
});

/**
 * @description 家访上门记录新增
 */
Api.visitCreate = opts => axios.request({
    url: `/api/app/home/caseHandle/case/home/visit/create`,
    data: opts,
});

/**
 * @description 家访上门记录分页查询
 */
Api.visitPage = opts => axios.request({
    url: `/api/app/home/caseHandle/case/home/visit/page`,
    data: opts,
});

/**
 * @description 家访回款记录新增
 */
Api.repaymentCreate = opts => axios.request({
    url: `/api/app/home/caseHandle/case/home/repayment/record/create`,
    data: opts,
});

/**
 * @description 家访回款记录分页查询
 */
Api.repaymentPage = opts => axios.request({
    url: `/api/app/home/caseHandle/case/home/repayment/record/page`,
    data: opts,
});

export default Api